.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

div.user-list-profile {
  width: 128px;
  height: 128px;
}

.user-list-name {
  line-height: 128px;
  height: 128px;
}

.user-list-name p {
  display: inline-block;
  vertical-align: middle;
}

input[type=number]#pin 
{
    -webkit-text-security: disc;
}

/* Custom, iPhone Retina */ 
@media only screen and (min-width : 320px) {
  footer {
    font-size: small;
  }

  .user-list-name {
    line-height: 64px;
    height: 64px;
  }

  div.user-list-profile {
    width: 64px;
    height: 64px;
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {
  footer {
    font-size: x-small;
  }

  .user-list-name {
    line-height: 32px;
    height: 32px;
  }

  div.user-list-profile {
    width: 32px;
    height: 32px;
  }
}

.list-item-vertical-center {
  height:100%;
  width:100%;

  font: 0/0 a;         /* remove the gap between inline(-block) elements */
}

.list-item-vertical-center:before {    /* create a full-height inline block pseudo=element */
  content: ' ';
  display: inline-block;
  vertical-align: middle;  /* vertical alignment of the inline element */
  height: 100%;
}

.list-item-vertical-center > div {
  max-width: 100%;
  display: inline-block;
  vertical-align: middle;  /* vertical alignment of the inline element */
  font: 16px/1 -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif;        /* <-- reset the font property */
}

.navbar-title-vertical-center {
  height:100%;
  width:100%;

  font: 0/0 a;         /* remove the gap between inline(-block) elements */
}

.navbar-title-vertical-center:before {    /* create a full-height inline block pseudo=element */
  content: ' ';
  display: inline-block;
  vertical-align: middle;  /* vertical alignment of the inline element */
  height: 100%;
}

.navbar-title-vertical-center > div {
  max-width: 100%;
  display: inline-block;
  vertical-align: middle;  /* vertical alignment of the inline element */
  font: 16px/1 -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif;        /* <-- reset the font property */
}

.input-label {
  text-align: left;
  width: 100%;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  font-size: small;
  font-weight: bold;
  padding-top: 3px;
  padding-left: 10px;
  padding-right: 10px;
}

.input-label-first {
  padding-top: 0 !important;
}

.input {
  width: 100%;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 3px;
}

.input-last {
  padding-bottom: 0 !important;
}

.input > input {
  width: 100%;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.input > select {
  width: 100%;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.error-message {
  color: red;
  width: 100%;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  background:lightgrey;
  padding-left: 10px;
  padding-right: 10px;
}

.bm-menu {
  background: #373a47;
}
.bm-menu a {
  color: #b8b7ad;
}
.bm-menu a:hover,
.bm-menu a:focus {
  color: #c94e50;
}
.bm-item-list a {
  padding: 0.8em;
}
.bm-item-list a span {
  margin-left: 10px;
  font-weight: 700;
}
.bm-item:focus {
  outline: none;
}